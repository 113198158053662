import React from "react";
import styles from "../../styles/main.module.css";
import err from "../../assets/error (1).png"
import { useNavigate } from "react-router-dom";
const Error = () => {
    const navigate=useNavigate()
    return (
        <div style={{  }} className={`${styles?.["flex-column"]} `}>
            <div style={{ width:"234px" }} >
<img style={{ width:"100%"}} src={err} alt="" />
            </div>
            
            <h1>Oops</h1>
            <h3 className={`${styles?.["center"]} `} >Oops! There was a problem. <br /> recorded  Please wait or try again later</h3>
            <div>
                <button onClick={()=>navigate("/")}  className={`${styles?.["outline-button"]} `} >Back Home</button>
            </div>
        </div>
    );
};
export default Error;
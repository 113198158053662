import React from "react";
import styles from "../../styles/main.module.css";
import whiteLine from "../../assets/linewhite-background.png";
import logo1 from "../../assets/Brand-logos/iitb1 2.png";
import logo2 from "../../assets/Brand-logos/Stanford-seed-logo-2 1.png";
import logo3 from "../../assets/Brand-logos/Nvidia_logo.svg 1.png";
import logo4 from "../../assets/Brand-logos/sharda1.jpg 1.png";
import logo5 from "../../assets/Brand-logos/sai new1.jpg 1.png";
import logo6 from "../../assets/Brand-logos/Sharda-Hospital-Logo 1.png";
import Typewriter from "../common/TypeWriter";
import VoiceToText from "../common/Voice";
const MainCover = () => {
  const brands = [logo1, logo2, logo3, logo4, logo5, logo6];
  const sentences=[
    "  Helping you re-imagine patient experience​",
    " Streamlining your Hospital Workflows​",
    " Enhancing your Staff Productivity by Automating Routine Tasks​​",
    " Becoming force multipliers overcoming staff shortage​",
    " Empowering your Staff to Focus on Critical Patient Care​​",
    " Available 24X7, an infinitely scalable cost optimized workforce​",
    " Elevating healthcare delivery standards​​",
    " Improving patient experience​​",

    


  ]
  return (
    <div
      style={{ minHeight: "49.0625rem" }}
      className={`${styles?.["gradiant-blue"]} ${styles?.["cover"]}  `}
    >
      {/* <div style={{ minHeight:"6rem" }}></div> */}
      <img style={{ height:"500px" }} className={`${styles?.["cover-image"]} `} src={whiteLine} alt="" />
      <div
        className={`${styles?.["cover-content"]} ${styles?.["flex-column"]} `}
      >
        <div style={{ gap: "5px" }} className={`${styles?.["flex-column"]} `}>
          <div className={`${styles?.["head-md"]} ${styles?.[""]} `}>
            Healthcare AI Teammates ​
          </div>
          <div
            className={`${styles?.["gradiant-pink-col"]} ${styles?.["head-lg"]} `}
          >
            HealthMates.AI
          </div>
          <div style={{ minHeight:"30px" }} className={`${styles?.["head-md"]}   ${styles?.["m-auto"]}  `}>
          <Typewriter sentences={sentences}/>
          </div>
        </div>

        <h5>
          Hire your first fully human-capable AI Agent today, integrated with
          your tech stack, capable of conversing, inferencing and acting{" "}
        </h5>
        <VoiceToText/>

        <div
          className={`${styles?.["flex-between"]} ${styles?.["brand-list"]}`}
        >
          {/* {brands?.map((img) => (
            <>
              <div style={{ width: "80px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={img} alt="" />
              </div>
            </>
          ))} */}
          <div style={{ width: "50px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={logo1} alt="" />
              </div>
              <div style={{ width: "151px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={logo2} alt="" />
              </div>
              <div style={{ width: "80px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={logo3} alt="" />
              </div>
              <div style={{ width: "151px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={logo4} alt="" />
              </div>
              <div style={{ width: "80px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={logo5} alt="" />
              </div>
              <div style={{ width: "120px" }} className={`${styles?.["w-35"]} `}>
                <img className={`${styles?.["w-100"]} `} src={logo6} alt="" />
              </div>

        </div>
      </div>
    </div>
  );
};
export default MainCover;

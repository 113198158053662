import React from "react";
import { Route,  Routes } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Research from "../pages/Research";
import Career from "../pages/Career";
import VoiceToText from "../components/common/Voice";
import ScrollToTop from "../components/common/ScrollToTop";


        
const AllRoutes = () => {

    return (
        <div style={{ maxWidth:"1480px",margin:"auto" }}>
        
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contactus" element={<Contact/>} />
            <Route path="/research" element={<Research/>} />
            <Route path="/career" element={<Career/>} />
            <Route path="/voice" element={<VoiceToText/>} />



        </Routes></div>
    );
};
export default AllRoutes;
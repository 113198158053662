export const botNameReducer = (state,action)=>{
    switch (action.type) {
        case "ADD_BOTNAME":
            return {
                ...state,
                data:action.payload
            }  
        default:
            return {
                ...state
            }
    }
}
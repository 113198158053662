import React from "react";

import styles from "../styles/main.module.css";
import doc2 from "../assets/About Us/doc1.jpg"
import doc1 from "../assets/About Us/doc2.jpg"

import MainCover from "../components/about/MainCover";
import GlobalShortfall from "../components/about/GlobalShortfall";
import ValuesSection from "../components/about/ValuesSections";
import NewsFeed from "../components/common/NewsFeed";

const About = () => {
  const values = [
    {
      title: "Enabling us to provide healthcare in every household",
      summary:
        "We can significantly expand healthcare access and the scalability of healthcare professionals with the safest artificial Health General Intelligence (HGI), which will lead to a more fair system of treatment for everyone.",
      img:doc1
    },
    {
      title: "We chose our name because we aspire to “first, do no harm”",
      summary:
        "The Healthmates Oath, the set of principles and ethics that has governed doctors and the larger healthcare community for decades, serves as our inspiration. We support evidence-based practice, prioritize the patient, and make sure our LLM comes first without causing harm.",
      img:doc2
    },
  ];
  return (
    < div data-aos="fade-down" className={`${styles?.["flex-column-str"]} ${styles?.[""]}  `}>
      <MainCover title="We are developing a large language model with a safety focus." subTitle="The global scarcity of healthcare workers is, in our opinion, the biggest threat to patient safety."/>\
      <div data-aos="fade-left">
      <GlobalShortfall/>
      </div>
      <div data-aos="fade-right"><ValuesSection/></div>
      
      <div data-aos="fade-left" className={`${styles?.["m-t-5"]} ${styles?.["m-b-5"]} `}>
      {
        values?.map((news,i)=>(
          <NewsFeed isLeft={i/2==0} title={news.title} img={news.img} summary={news.summary}/>
        ))
      }
      
     </div>

    </div>
  );
};
export default About;

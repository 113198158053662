import React from "react";
import styles from "../../styles/main.module.css";
import logo from "../../assets/healthmates-new-logo.png";
import { redirect, useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className={`${styles?.["flex-between"]} ${styles?.["navbar"]}`}>
      <div
        style={{ gap: "6rem" }}
        className={`${styles?.["flex-gap"]} ${styles?.["w-50"]}`}
      >
        <div
          onClick={() => navigate("/")}
          style={{ width:"200px" }}
          className={`  ${styles?.["pointer"]}  `}
        >
          <img style={{ width: "100%" }} src={logo} alt="" />
        
        </div>
        <div className={`${styles?.["flex-between"]} ${styles?.["w-40"]}`}>
          {/* <div
            onClick={() => navigate("/about")}
            className={` ${styles?.["pointer"]}  ${
              window.location.pathname != "/about"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            About us
          </div> */}
          <div
            onClick={() => navigate("/contactus")}
            className={` ${styles?.["pointer"]}  ${
              window.location.pathname != "/contactus"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            {" "}
            Contact us
          </div>
          <div
            onClick={() => navigate("/research")}
            className={` ${styles?.["pointer"]}  ${
              window.location.pathname != "/research"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            {" "}
            Research
          </div>
        </div>
      </div>
      <button
        onClick={() => navigate("/contactus")}
        className={`${styles?.["gradiant-button"]} ${styles?.["primary-color"]} ${styles?.["req-button"]}`}
      >
        Request Demo
      </button>
    </div>
  );
};
export default Navbar;

import React from "react";   
import styles from "../../styles/main.module.css"
import protection from "../../assets/Home Page/Protection.jpg"
import globe from "../../assets/Home Page/image 2609.png"
import integration from "../../assets/Home Page/Group 1719.png"
import performance from "../../assets/Home Page/Group 1509.png"
import stacks from "../../assets/Home Page/stacks.jpg 1.png"
import platform from "../../assets/Home Page/image 2610.png"
import ROI from "../../assets/Home Page/image 2607.png"
import FeatureCard from "./FeatureCard";


const FeaturesSection = () => {
    const features=[
        {
            img:globe,
            title:"World Class Team",
            subTitle:"A white glove service team"

        },
        {
            img:integration,
            title:"Seamless Integration",
            subTitle:"Integrating with all healthcare applications in  the cloud and on-prem"

        },
        {
            img:performance,
            title:"Performance-Based Contracting",
            subTitle:"Savings share or share of incremental revenue"

        },
        {
            img:stacks,
            title:"Vendor Consolidation",
            subTitle:"One platform, all departments, reduce tool and vendor spend"

        },
        {
            img:platform,
            title:"Custom Built Platform",
            subTitle:"Optimizing your current system stack, saving you millions from costly system migrations"

        },
        {
            img:ROI,
            title:"ROI Obsessed",
            subTitle:"Adding millions in value to you"

        },
    ]
    return (
        <div className={`${styles?.["white-banner"]} ${styles?.[""]}  ${styles?.["flex-column"]}  `}>
            <h1 style={{ fontSize:"1.7rem" }} className={`${styles?.["w-60"]} `} >Watch our AI Agents easily handle your most challenging health-care processes </h1>
          <div className={`${styles?.["feature-container"]} `}>
            {
             features?.map((feature,i)=>(
                <>
                {
                    i==1 || i==3?<div style={{ gridRow:"span 2" }}>
                        <FeatureCard i={i} img={feature.img} title={feature.title} subTitle={feature.subTitle} />
                    </div>:<FeatureCard img={feature.img} title={feature.title} subTitle={feature.subTitle} />
                }
                
                </>
             ))
            }
          </div>
             
        </div>
    );
};
export default FeaturesSection;
import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import whiteLine from "../../assets/Home Page/video-play-banner.png";
import ReactPlayer from "react-player";

const VideoBanner = () => {
  const [isPlay, setIsPlay] = useState(false);
  return (
    <div style={{ backgroundColor:isPlay?"black":"" }} className={`${styles?.["video-banner"]}`}>
      {isPlay ? (
        <>
          {" "}
          
            <ReactPlayer
              url="https://healthmates.ai/repo/website_video.mp4"
              controls={true} // Shows playback controls
              playing={isPlay} // Autoplay video
              // height={"100%"}
              volume={0.8} // Set volume level (0 to 1)
              loop={true} // Loop video
              muted={false} // Mute audio
              width="100%"
              height="100%"
            />
            {/* <video autoPlay controls src="https://www.youtube.com/watch?v=OKOQQNgOS1I"></video> */}
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/9ubIKipLpRU?si=FLIpjqETmzxOxMwl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
         
        </>
      ) : (
        <>
          {" "}
          <img
            style={{ height: "362px" }}
            className={`${styles?.["cover-image"]} `}
            src={whiteLine}
            alt=""
          />
          <div
            className={`${styles?.["cover-content"]} ${styles?.["flex-column"]} `}
          >
            <h1
              className={`${styles?.["w-80"]} ${styles?.["font-w-4"]} ${styles?.["m-auto"]} ${styles?.["center"]}`}
            >
              AI Healthmates in Action: Sharda
            </h1>
            <div onClick={()=>setIsPlay(true)} className={`${styles?.["pointer"]}`}>
              <svg
                width="62"
                height="62"
                viewBox="0 0 82 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M41 82C46.3842 82 51.7157 80.9395 56.69 78.8791C61.6644 76.8186 66.1842 73.7986 69.9914 69.9914C73.7986 66.1842 76.8186 61.6644 78.8791 56.69C80.9395 51.7157 82 46.3842 82 41C82 35.6158 80.9395 30.2843 78.8791 25.31C76.8186 20.3356 73.7986 15.8158 69.9914 12.0086C66.1842 8.20142 61.6644 5.18138 56.69 3.12094C51.7157 1.0605 46.3842 -8.02308e-08 41 0C30.1261 1.62033e-07 19.6976 4.31963 12.0086 12.0086C4.31963 19.6976 0 30.1261 0 41C0 51.8739 4.31963 62.3024 12.0086 69.9914C19.6976 77.6804 30.1261 82 41 82ZM35.4559 22.7322L61.1674 37.0184C61.8773 37.4131 62.4687 37.9903 62.8805 38.6904C63.2922 39.3904 63.5093 40.1878 63.5093 41C63.5093 41.8122 63.2922 42.6096 62.8805 43.3096C62.4687 44.0097 61.8773 44.5869 61.1674 44.9816L35.4559 59.2678C34.6234 59.7305 33.6844 59.9677 32.7319 59.9559C31.7795 59.944 30.8467 59.6835 30.026 59.2002C29.2052 58.7168 28.525 58.0274 28.0527 57.2003C27.5804 56.3731 27.3324 55.4369 27.3333 54.4844V27.5156C27.3324 26.5631 27.5804 25.6269 28.0527 24.7997C28.525 23.9726 29.2052 23.2832 30.026 22.7998C30.8467 22.3165 31.7795 22.056 32.7319 22.0441C33.6844 22.0323 34.6234 22.2695 35.4559 22.7322Z"
                  fill="#EBEBEB"
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default VideoBanner;

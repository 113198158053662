import React from "react";
import MainCover from "../components/home/MainCover";
import styles from "../styles/main.module.css";
import FeedBack from "../components/home/Feedback";
import profile from "../assets/profile-images/image 2620.png";
import LanguageSection from "../components/home/LanguageSection";
import ProtectionSection from "../components/home/ProtectionSection";
import GoalSection from "../components/home/GoalSection";
import FeaturesSection from "../components/home/FeaturesSection";
import SecuritySection from "../components/home/SecuritySection";
import Media from "../components/home/Media";

const Home = () => {
  return (
    < div data-aos="fade-down" className={`${styles?.["flex-column-str"]} ${styles?.[""]}  `}>
      <MainCover />
      <div
      data-aos="fade-left"
        style={{ gap: "10%" }}
        className={`${styles?.["flex-gap"]} ${styles?.["w-80"]} ${styles?.["m-auto"]}  `}
      >
        <h1 className={`${styles?.["w-90"]} ${styles?.[""]}  `}>
          Introducing fully human capable AI Agents powering the best healthcare
          providers
        </h1>
        <FeedBack
          name="Mr Sunel Satya Kapur "
          designation="CEO, Sharda Care HealthCity"
          feedback="“It’s like having a perfect employee, that works 24 hours a day, exactly how you trained it - becoming a force multiplier for the entire organization”"
          profile={profile}
        />
      </div>
      <div data-aos="fade-right"><LanguageSection/></div>
      <div data-aos="fade-left"><ProtectionSection/></div>
      <div data-aos="fade-right"> <GoalSection/></div>
      <div data-aos="fade-left"><FeaturesSection/></div>
      <div data-aos="fade-right"><SecuritySection/>
      </div>
      <div data-aos="fade-left"> <Media/></div>
          </div>
  );
};
export default Home;

import React from "react";
import styles from "../../styles/main.module.css";
import english from "../../assets/Home Page/English.jpg";
import hindi from "../../assets/Home Page/Hindi.jpg";
import multiLingual from "../../assets/Home Page/Multi lingual.jpg";
import marathi from "../../assets/Home Page/Marathi.jpg";
import bengali from "../../assets/Home Page/Bengali.jpg";
import punjabi from "../../assets/Home Page/Mask group (5).jpg";
import telgu from "../../assets/Home Page/Telugu.jpg";
import tamil from "../../assets/Home Page/Tamil.jpg";
import { type } from "@testing-library/user-event/dist/type";
import LanguageCard from "./LanguageCard";
import InfoBanner from "./InfoBanner";
import VideoBanner from "./VideoBanner";

const LanguageSection = () => {
  const languages = [
    {
      img: english,
      name: "Sharda",
      language: "English",
      title: "General Medicine Triage",
      specialization: "General",
      type: "Appointment Booking",
      action: "Voice & Messaging Capable",
    },
    {
      img: hindi,
      name: "Madhu",
      language: "Hindi",
      title: "Assisted Living",
      specialization: "Geriatric",
      type: "Check-In",
      action: "Voice & Vision Capable",
    },
    {
      img: multiLingual,
      name: "Hrithik",
      language: "Multi-lingual",
      title: "Nutrition Assistance",
      specialization: "General",
      type: "Appointment Booking",
      action: "Voice & Messaging Capable",
    },
    {
      img: marathi,
      name: "Namita",
      language: "Marathi",
      title: "General Medicine Triage",
      specialization: "General",
      type: "Appointment Booking",
      action: "Voice & Messaging Capable",
    },
    {
      img: bengali,
      name: "Poulomi",
      language: "Bengali",
      title: "Post Discharge",
      specialization: "Orthopedics",
      type: "Follow-Up",
      action: "Voice & Vision Capable",
    },
    {
      img: punjabi,
      name: "Harvinder",
      language: "Punjabi ",
      title: "Ongoing: Dialysis Care",
      specialization: "Nephrology",
      type: "Check-In",
      action: "Voice & Messaging Capable",
    },
    {
      img: telgu,
      name: "Preethi",
      language: "Telugu ",
      title: "Post Discharge",
      specialization: "Cardiology",
      type: "Follow-Up",
      action: "Voice & Messaging Capable",
    },
    {
      img: tamil,
      name: "Akash ",
      language: "Tamil",
      title: "Ongoing: Dialysis Care",
      specialization: "Endocrinology",
      type: "Follow-Up",
      action: "Voice & Messaging Capable",
    },
  ];
  return (
    <div className={`${styles?.["w-90"]} ${styles?.["m-auto"]} `}>
      <h1
        className={`${styles?.["center"]} ${styles?.["w-80"]}  ${styles?.["m-auto"]} ${styles?.["p-t-3"]}  ${styles?.["p-b-3"]}  `}
      >
        Hire your first fully human-capable AI Healthmates today, integrated
        with your tech stack, capable of conversing, inferring, and acting
      </h1>
      <div className={`${styles?.["language-container"]} ${styles?.["wrap"]}`}>
        {languages?.map((lan, i) => (
          <>
            {i == 2 ? (
              <>
                <div style={{ gridColumn: "span 2" }}>
                  <VideoBanner />
                </div>
              </>
            ) : (
              <></>
            )}
            {i == 6 ? (
              <>
                <div style={{ gridColumn: "span 2" }}>
                  <InfoBanner />
                </div>
              </>
            ) : (
              <></>
            )}
            <LanguageCard
              name={lan.name}
              language={lan.language}
              title={lan.title}
              img={lan.img}
              specialization={lan.specialization}
              type={lan.type}
              action={lan.action}
            />{" "}
          </>
        ))}
      </div>
    </div>
  );
};
export default LanguageSection;

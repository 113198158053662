import logo from './logo.svg';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import AllRoutes from './routes/AllRoutes';
import tab from "./assets/Resposive/Healthmates Tab.jpg"
import mob from "./assets/Resposive/Healthmates Mob.jpg"
import Navbar from './components/header/Navbar';
import Footer from './components/footer/footer';
import Spacer from './components/common/Spacer';

function App() {
  AOS.init({
    duration:2000
  });


  return (
    <>
    {
      
        window.innerWidth>1000?<>
         <Navbar/>
    <AllRoutes/>
    <Spacer height={"5rem"}/>
    <Footer/>
       
        </>:window.innerWidth>700?<><img style={{ width:"100%",objectFit:"contain" }} src={tab}/></>:<><img style={{ width:"100%",objectFit:"contain" }} src={mob}/></>
      }
    
    </>
  
  );
}

export default App;

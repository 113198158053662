import React from "react";   
import styles from "../../styles/main.module.css"
import protection from "../../assets/Home Page/Protection.jpg"
const ProtectionSection = () => {
    return (
        <div className={`${styles?.["white-banner"]} ${styles?.[""]}  ${styles?.["flex-column"]}  `}>
            <h1 className={`${styles?.["w-60"]} `} >Watch our AI Agents easily handle your most challenging health-care processes </h1>
          <p className={`${styles?.["w-60"]} ${styles?.["secondary-light-color"]} `} >
           Healthmates.AI seamlessly do the work of a fully staffed healthcare support team. They're your team's favorite new coworkers
          </p>
          <p className={`${styles?.["flex-gap"]} ${styles?.["primary-dark-color"]} ${styles?.["m-auto"]}   `} >
            <div style={{ width:"50px" }}><img className={`${styles?.["w-100"]} `} src={protection} alt="" /></div>
            <p>
              <b>
            PDP, GDPR, HIPAA & SOC 2 <br />compliant out of the box</b>
            </p>
          </p>
             
        </div>
    );
};
export default ProtectionSection;
import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";

const ValuesSection = () => {
  const values = [
    {
      title: "Do No Harm",
      subtitle:
        "Our products must be more secure than people performing the same task.",
    },
    {
      title: "Patients First",
      subtitle:
        "Above all, we are here to help people achieve better health results.",
    },
    {
      title: "Equal Access for All",
      subtitle:
        "Artificial intelligence should be accessible to everyone in society.",
    },
    {
      title: "Creativity",
      subtitle:
        "In order to usher in a world of healthcare abundance, team members and partners must adopt new perspectives.",
    },
    {
      title: "Humanity",
      subtitle:
        "We pledge to respect and empathize with the full person, seeing behind their symptoms.",
    },
  ];
  return (
    <div
      style={{ gap: "4rem",position:"relative" }}
      className={`${styles?.["m-auto"]} ${styles?.["w-100"]} ${styles?.["flex-column"]}  ${styles?.["value-cover"]} `}
    >
      <div className={`${styles?.["value-img"]}`}>
        <img style={{ width:"100%" }} src={stats} alt="" />
      </div>
      <h1 className={`${styles?.["head-lg"]}  `}> Our Values</h1>

      <div className={`${styles?.["w-70"]} ${styles?.["flex-column-str"]}   `}>
        {values?.map((v, i) => (
          <>
            <div
              style={{ alignItems: "flex-start", gap: "1.5rem" }}
              className={` ${styles?.["flex-gap"]}   `}
            >
              <div
                className={`${styles?.["value-circle"]} ${styles?.["w-35"]}    `}
              >
                {i + 1}
              </div>
              <div className={`${styles?.["w-80"]}    `}>
                <div className={` ${styles?.["head-md"]}`}>{v.title}</div>
                <p
                  style={{ fontWeight: "300" }}
                  className={` ${styles?.["head-sm"]}`}
                >
                  {v.subtitle}
                </p>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
export default ValuesSection;

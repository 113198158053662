import React from "react";
import styles from "../../styles/main.module.css"
import stats from "../../assets/About Us/data.jpg"
        
const GlobalShortfall = () => {
    return (
        <div  className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-column"]}  `}>
            <h1 className={`${styles?.["head-lg"]}   `}>Global shortfall of 15 million healthcare workers</h1>
            <div className={`${styles?.["w-60"]}   `}  >
                <img className={`${styles?.["border-rds-1"]} ${styles?.["w-100"]}  `} src={stats} alt="" />
            </div>
             
        </div>
    );
};
export default GlobalShortfall;
import React from "react";   
import styles from "../../styles/main.module.css"
const FeedBack = ({feedback="",profile="",name="",designation=""}) => {
    return (
        <div className={`${styles?.["feedback-container"]} ${styles?.["w-50"]}  `}>
            <div className={`${styles?.["feedback-container-border"]} `} > </div>
          <p className={`${styles?.["head-sm"]} ${styles?.["w-100"]}  `}>
           {feedback}
          </p>
          <div style={{ gap:"1rem" }} className={`${styles?.["flex-gap"]} `} >
            <div style={{ width:"60px" }}><img className={`${styles?.["w-100"]} `} src={profile} alt="" /></div>
            <div>
                <div style={{ fontSize:".8rem" }}><b>{name}</b></div>
                
                <div style={{ fontSize:".8rem" }}>{designation}</div>
            </div>
          </div>
             
        </div>
    );
};
export default FeedBack;
export const chat_bot= async (data) => {
    const headers={
      // Accept: "application/json",
      // "Content-type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    }
  
      const response = await fetch(process.env.REACT_APP_API + "/bot/make_webhook_post/", {
          method: "POST",
          headers: headers,
          body: data
      });
      if (response.status === 200 || response.status === 201) {
          const data = await response.json();
          return data;
        } else if (response.status === 405 || response.status === 403) {
          // settextData(response.status);
          // setIsModal(true);
          localStorage.clear();
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          return [];
        } else if (response.status !== 200 || response.status !== 201) {
          return [];
        }
  
  }
  export const delete_bot= async (data) => {
    const headers={
      // Accept: "application/json",
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  
      const response = await fetch(process.env.REACT_APP_API + "/bot/delete_data/", {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data)
      });
      if (response.status === 200 || response.status === 201) {
          const data = await response.json();
          return data;
        } else if (response.status === 405 || response.status === 403) {
          // settextData(response.status);
          // setIsModal(true);
          localStorage.clear();
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          return [];
        } else if (response.status !== 200 || response.status !== 201) {
          return [];
        }
  
  }

  export const check_user= async (data) => {
    const headers={
      // Accept: "application/json",
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  
      const response = await fetch(process.env.REACT_APP_API + "bot/check_or_create_user_number/", {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data)
      });
      if (response.status === 200 || response.status === 201) {
          const data = await response.json();
          return data;
        } else if (response.status === 405 || response.status === 403) {
          // settextData(response.status);
          // setIsModal(true);
          localStorage.clear();
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          return [];
        } else if (response.status !== 200 || response.status !== 201) {
          return [];
        }
  
  }
  export const create_user= async (data) => {
    const headers={
      // Accept: "application/json",
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  
      const response = await fetch(process.env.REACT_APP_API + "/bot/check_or_create_user_number/", {
          method: "POST",
          headers: headers,
        //   body: JSON.stringify({})
      });
      if (response.status === 200 || response.status === 201) {
          const data = await response.json();
          return data;
        } else if (response.status === 405 || response.status === 403) {
          // settextData(response.status);
          // setIsModal(true);
          localStorage.clear();
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          return [];
        } else if (response.status !== 200 || response.status !== 201) {
          return [];
        }
  
  }

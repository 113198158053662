import React from "react";

import styles from "../styles/main.module.css";
import olympic from "../assets/Research/image 2674.jpg";
import darwin from "../assets/Research/image 2675.jpg";
// import conferrence from "../assets/Research/image 2676.jpg";
import pushup from "../assets/Research/pushup.png"
import conferrence from "../assets/Research/conferrence.png"
import cycle from "../assets/Research/cycle.png"
import brain from "../assets/Research/brain.png"
import headline from "../assets/Research/headline.png"
import conferrenceGoogle from "../assets/Research/conferrence-google.png"
import MainCover from "../components/about/MainCover";
import NewsFeed from "../components/common/NewsFeed";
import JoinTeam from "../components/common/JoinTeam";
import Spacer from "../components/common/Spacer";



const Research = () => {
  const news = [
    {
      title: "CNBC-TV18",
      informerLink: "@IANS wire service",
      Date: "November 20, 2024",
      summary:"Shitiz Ruhail, Co-Founder of Digital Darwin, on Navigating Digital Darwinism with Innovation and Agility",
      img: pushup,
      link:"https://www.ians.in/vmpl/shitiz-ruhail-co-founder-of-digital-darwin-on-navigating-digital-darwinism-with-innovation-and-agility"
    },
    {
      title:
        "Digital Darwin: Recolonizing Sports Tech with computer vision... ",
      informerLink: "@Mirror Now",
      Date: "November 16, 2024",
      summary:"Generational Clash At Work: Gen Z Redefines 9-to-5. Will 'Traditionals' Catch Up?",
      img: headline,
      link:"https://youtu.be/kBID09bzPSM?si=LWmo18dPuqlC4NBY"
    },
    {
      title: "Digital Darwin Partners with Google to Enhance AI",
      informerLink: "@Firstpost",
      Date: "November 7, 2024",
      summary:
        "The $1 tn conversation: How AI assistants will revolutionise our lives",
      img: brain,
      link:"https://www.firstpost.com/connect/the-1-tn-conversation-how-ai-assistants-will-revolutionise-our-lives-13832983.html"
    },
    {
      title: "CNBC-TV18",
      informerLink: "@CXOtoday",
      Date: "November 7, 2024",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      img: cycle,
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"
    },
    {
      title:
        "Digital Darwin: Recolonizing Sports Tech with computer vision... ",
      informerLink: "@IANS wire service",
      Date: "October 7, 2024",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      img: conferrence,
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

    },
    {
      title: "Digital Darwin Partners with Google to Enhance AI",
      informerLink: "@CNBC-TV18",
      Date: "July 26, 2024",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India, @iitmadras has its sights set on #Olympics 2036",
      img: conferrenceGoogle,
      link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
    },
  ];
  return (
    <div data-aos="fade-down" className={`${styles?.["flex-column-str"]} ${styles?.[""]}  `}>
      <MainCover
        title="Research & Safety Blog"
        subTitle="New LLM and Clinical Updates"
      />
      <div
        style={{ gap: "2rem" }}
        className={`${styles?.["m-t-5"]} ${styles?.["m-b-5"]} ${styles?.["flex-column"]}  `}
      >
        {news?.map((news, i) => (
          <div data-aos={i%2==0?"fade-right":"fade-left"}>
          <NewsFeed
            isLeft={false}
            isNews={true}
            title={news.title}
            img={news.img}
            summary={news.summary}
            informerLink={news.informerLink}
            date={news.Date}
            link={news.link}
          />
          </div>
        ))}
      </div>
      <JoinTeam/>
      <Spacer height={"2rem"}/>


    </div>
  );
};
export default Research;

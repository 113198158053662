import React from "react";
// import styles from './index.module.less';
        
const Spacer = ({height}) => {
    return (
        <div style={{ minHeight:height }}>
             
        </div>
    );
};
export default Spacer;
import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/About Us/background.jpg"


const MainCover = ({title="",subTitle=""}) => {


  return (
    <>
    <div>
    <div
      style={{ minHeight: "300px" ,backgroundImage:`url(${background})` }}
      className={`${styles?.["about-cover"]} ${styles?.["p-l-3"]} ${styles?.["flex-column-end"]}  `}
    >
      <h1 className={`${styles?.["w-50"]} ${styles?.["m-l-3"]} `}>{title}</h1>
     
    </div>
    <div  className={`${styles?.["gradiant-button"]} ${styles?.["p-t-3"]} ${styles?.["p-b-3"]} `}>
      <h3 className={`${styles?.["w-50"]} ${styles?.["m-auto"]} ${styles?.["center"]}  `} >
    {subTitle}</h3>
</div>
    </div>
    </>
  );
};
export default MainCover;

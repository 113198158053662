import React from "react";
import styles from "../../styles/main.module.css";
const FeatureCard = ({
  img = "",
  title = "",
  subTitle = "",
  i=null
}) => {
  return (
    <div className={`${styles?.["flex-column"]}  `} style={{ height:"100%",justifyContent:"center" }}>
    <div className={`${styles?.["feature-card"]} ${styles?.["m-auto"]} `}>
      <div  className={`${styles?.["w-80"]} ${styles?.["m-auto"]} `} >
        {/* <img style={{ width:"100%",padding:i==1 ? "13rem 0rem":i==2?"2rem 0rem":i==3?"4rem 0rem":"0" }} src={img} alt="" /> */}
        <img style={{ width:"100%"}} src={img} alt="" />
        </div>
      

      <div className={`${styles?.["m-l-3"]} ${styles?.["feature-content"]}`}>
        <div className={`${styles?.["head-md"]}`}>{title}</div>
        <div style={{ color:"#868686",fontWeight:"400" }} className={`${styles?.["head-sm"]}  ${styles?.["p-t-1"]}`}>{subTitle}</div>
      </div>
      
    </div></div>
  );
};
export default FeatureCard;

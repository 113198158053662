import React from "react";
import styles from "../../styles/main.module.css";
import bn from "../../assets/Home Page/1bn.jpg";
const GoalSection = () => {
  return (
    <div
      style={{ backgroundImage: `url(${bn})`, height: "200px" }}
      className={`${styles?.["blue-banner"]} ${styles?.[""]}  ${styles?.["flex-column"]}  `}
    >
      <h1 className={`${styles?.["w-80"]} `}>
        AI Healthmates will generate{" "}
        <span className={`${styles?.["gradiant-pink-col"]} `}>
          {"> $1Bn in value"}
        </span>
        <br /> for healthcare providers in the coming years
      </h1>
    </div>
  );
};
export default GoalSection;
